import axios from "axios";

const API = process.env.VUE_APP_API_URL + "/votes";

export default {
  get(id) {
    return axios.get(API + "/" + id);
  },
  create(data, token) {
    return axios.post(process.env.VUE_APP_API_URL + "/app/votes", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    });
  },
};
