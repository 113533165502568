<template>
  <div>
    <v-row>
      <v-col cols="11" md="7" class="mx-auto">
        <v-img src="@/assets/tuvoto.png"></v-img>
      </v-col>
    </v-row>
    <v-card flat>
      <v-card-text class="pt-5">
        <previewForm :election="election" :from="this.$route.params.from"></previewForm>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import votes from "@/services/votes";
import previewForm from "@/components/preview/Form";

export default {
  components: {
    previewForm
  },
  data: () => ({
    election: null
  }),
  mounted() {
    let self = this;
    votes
      .get("preview-" + this.$route.params.id)
      .then(response => {
        self.election = response.data.election;
        self.election["id"] = parseInt(this.$route.params.id);
      })
      .catch(error => {
        if (error.response.status == 422) {
          this.message = error.response.data.message;
        } else {
          console.log(error);
        }
      });
  }
};
</script>